import React from 'react';

import './App.css';
import Resetpassword from './components/resetpassword'
import Action from './components/Action'
import Success from './components/Success.js'
import Test from './components/test.js'
import { Route, Switch ,BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
      <BrowserRouter>
    <Switch>
    <Route path="/auth" component={Resetpassword} />
   <Route path="/Success" component={Success} />
    <Route path="/Test" component={Test} />
     <Route path="/action" component={Action} />
</Switch>
  </BrowserRouter>
   
  );
}

export default App;
