import React from 'react';
import firebase from '../firebase.js';
import queryString from 'query-string'
//import { Redirect } from "react-router-dom";
import {  FormGroup, FormControl,Button, Card,Alert,Image} from 'react-bootstrap';
import './login.sass';
import  image from './Logo.png';
import phide from'./phide.png';
import pview from './passwordview.png'
export default class Resetpassword extends React.Component {

    constructor(props) {
    super(props);
 this.myRef = React.createRef()
    this.state = {
      acceptedTerms: false,
      password: '',
      authcode:'',
      error:'',
      success:'',
        type: 'password',
         mode:'',
         verifyemailmessage:'',
         message:'',
    };
      this.showHide = this.showHide.bind(this);
  }
  updateCheckbox(checked) {
    this.setState({
      acceptedTerms: checked,
    });
  }

  updateEmail(value) {
    this.setState({
      password: value,
    });
  }
  onAuthSuccess =  async (result) => {
     // <Redirect to="/Success" />
 console.log('Password reset successfully')
 this.setState({ success: 'Password reset successfully'});
  }
validatepassword = (password) => {
//var re = /^[a-zA-Z0-9]{8,20}$/;
//var re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
var re = /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{10,36}$/;
  return re.test(password);

}
onSubmit = event => {

 this.setState({ error:null,success:null });
 this.myRef.current.scrollTo(0, 0);
    if(this.state.password === ""){
        alert("Password cannot be blank")
    }
else if ( this.validatepassword(this.state.password)===true){

     firebase.auth().confirmPasswordReset(this.state.authcode, this.state.password)
    .then(this.onAuthSuccess)
      .catch(error => {
        this.setState({ error });
      //error = 'Password reset failed'
       //this.setState({ error });
       //this.setState({ error: 'Password reset failed' });
      
       
      });
      
}
     else{
            alert("Min 10 and max 36 characters with an upper case letter, a numeric character, and a special character ")
     }
    event.preventDefault();
  };

  submit() {
     this.setState({ error:null,success:null });
      console.log("  Password: "+this.state.password)
    firebase.auth().confirmPasswordReset(this.state.authcode, this.state.password)
    .then(function() {
      //  console.log('Success')
        alert('Success')
      // Success
    })
    .catch(function(err) {
        console.log('error',err)
      // Invalid code
    })
  }
  componentDidMount() {
    var that=this
    that.myRef.current.scrollTo(0, 0);
    const parsed = queryString.parse(this.props.location.search);
     //  console.log('parsed',parsed)
        this.setState({
      authcode: parsed.oobCode,
         mode: parsed.mode,
    });
if (parsed.mode==='verifyEmail') 
{
     firebase.auth().applyActionCode(parsed.oobCode)
    .then(function(res) {
       // console.log('Success',res)
       // alert('Success')
          that.setState({
      verifyemailmessage: 'You can now sign in with your new account',
       message:'Your email has been verified', 
    });
      // Success
    })
    .catch(function(err) {
        //console.log('error',err.message)
          that.setState({
      verifyemailmessage: err.message,
        message:'Try verifying your email again'
    });
  

       
      // Invalid code
    })
  }
  }
  showHide(e){
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })  
  }

   render() {
      const {error ,success,mode} = this.state;
       let button;
       //  console.log('error',error)
    if (mode==='resetPassword') {
      button =   <div className="Login" ref={this.myRef}>
             <center>
                                    <Image src={image} style={{ width:120,height:120}} />
                                    <h1>  JiffyBook</h1>
                                   </center>
                                   
                   <form onSubmit={this.onSubmit} >
                   {!error&&!success?
                      <Card style={{background:'#00000000',borderBottomLeftRadius:25,borderBottomRightRadius:25,borderTopLeftRadius:25,borderTopRightRadius:25}}>
                       <center> <Card.Header style={{background :"#E75600",borderTopLeftRadius:15,borderTopRightRadius:15}}> <b><p style={{color:'white',fontWeight:'bold'}}>Reset Password</p> </b></Card.Header></center>
                        <Card.Body style={{ background :"#FFC926",borderBottomLeftRadius:15,borderBottomRightRadius:15,}}  >
                       <b>   <p>New Password</p> </b>
                            <FormGroup controlId="password"  style={{background:'#00000000'}}>
                                                
                            <FormControl type={this.state.type}  name="password" placeholder="Enter your password" 
                             placeholderTextColor="#515151"
                             onChange={(event) => {this.updateEmail(event.target.value)}}
                              value={this.state.email} style={{   background: '#E756001A' ,shadowOpacity: 0.8,
      borderRadius:30, borderColor:'#E75600'}}  />
                           <span style={{position: 'absolute',marginTop:-30,marginLeft:220}} className="password__show" onClick={this.showHide}>{this.state.type === 'input' ?  <Image src={pview} style={{ width:20,height:20}} /> :  <Image src={phide} style={{ width:20,height:20}} />}</span>
                        </FormGroup>
                        <center><Button type="submit" variant="Warning"  className="btn"  style={{background:'#E75600',color:'white', borderRadius:30,fontWeight:'bold'}}>SUBMIT</Button></center>
                        </Card.Body>
                        </Card>
                        :null
                   }
                        {error &&  <label style={{textAlign:'center',backgroundColor:'#ffccbc00',borderRadius:10,color:'#bf360c',padding:10,fontWeight:'bold'}}> {error.message}</label>}
                         {success && <center><label style={{textAlign:'center',backgroundColor:'#c8e6c900',borderRadius:10,color:'#1b5e20',padding:10,fontWeight:'bold'}}>{success}</label></center>}
                    </form>
                    
              
            </div>;
    } else if (mode==='verifyEmail')  {
      button = <h1>{this.state.verifyemailmessage}</h1>;
   button = <div className="Login" ref={this.myRef}>
             <center>
                                    <Image src={image} style={{ width:120,height:120}} />
                                    <h1>  JiffyBook</h1>
                                   </center>
                   <form onSubmit={this.onSubmit} >
                      <Card  style={{background:'#00000000',borderBottomLeftRadius:25,borderBottomRightRadius:25,borderTopLeftRadius:25,borderTopRightRadius:25}}  >
                       <center> <Card.Header style={{background :"#E75600",borderTopLeftRadius:15,borderTopRightRadius:15}}><b><p style={{color:'white',fontWeight:'bold'}}>{this.state.message}</p></b></Card.Header></center>
                        <Card.Body style={{ background :"#FFC926",borderBottomLeftRadius:15,borderBottomRightRadius:15,}} >
                         <center>  <b> <p style={{color:'black'}}>{this.state.verifyemailmessage} </p></b>
                           </center>
                       
                        </Card.Body>
                        </Card>
                        
                         {error &&  <label style={{textAlign:'center',backgroundColor:'#ffccbc00',borderRadius:10,color:'#bf360c',padding:10,fontWeight:'bold'}}> {error.message}</label>}
                         {success && <label style={{textAlign:'center',backgroundColor:'#c8e6c900',borderRadius:10,color:'#1b5e20',padding:10,fontWeight:'bold',}}> {success}</label>}
                    </form>
              
            </div>;
     
    }
      return (
       
     
    
     
    
         <div  style={{  background : "linear-gradient( #FFC926, #E75600 )",width:'100%',height:900,position: 'absolute' }} className="con" ref={this.myRef}>
        
                        <div className="logo">
                             {button}              
                                      
      
            </div>
              </div>
      );
   }
}