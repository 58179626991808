import firebase from 'firebase/app';
import 'firebase/auth';
// Initalize and export Firebase.
const config = {
    apiKey: "AIzaSyBlAuR15-nklQR0LEKxFHT4iLOnbT4hWyI",
    authDomain: "jiffybook-preprod.firebaseapp.com",
    databaseURL: "https://jiffybook-preprod.firebaseio.com",
    projectId: "jiffybook-preprod",
    storageBucket: "jiffybook-preprod.appspot.com",
    messagingSenderId: "538384824023",
};
export default firebase.initializeApp(config);



