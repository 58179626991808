import React, { Component } from 'react';
import firebase from '../firebase.js';

export default class Test extends Component {
  state = {
    error: '',
    validCode: false,
    verifiedCode: false,
  }

  componentDidMount() {
    // Try to apply the email verification code.
    console.log("asdsafdf")
   this.setState({ validCode: true, verifiedCode: true });
   console.log(this.state.validCode,this.state.verifiedCode,this.state.error)
    console.log("asdsafdf")
 firebase.auth().applyActionCode(this.props.actionCode)
      .then(() => {
        // Email address has been verified.
        console.log("Email address has been verified.  ")
        this.setState({ validCode: true, verifiedCode: true });
      }, error => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
          console.log("Email address has been not verified.  ")
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
      });






  }

   render() {
  
return(


          


 <div className="VerifyEmail">
          <h1>Your email has been verified</h1>
          <p>You can now sign in with your new account</p>
        </div>


  

  
)
  
  }
}
