import React from 'react';
import { FormGroup, FormControl, Button, } from 'react-bootstrap';
import './login.sass';
export default class Success extends React.Component {
      render() {
      
      return (

<div className="Login">

                
                    <form >
                        <FormGroup controlId="email">
                            
                            <FormControl type="text" name="email" placeholder="Enter your email"  />
                       
                        </FormGroup >
                        <FormGroup controlId="password" >
                          
                            <FormControl type="password" name="password" placeholder="Enter your password"  />
                        
                        </FormGroup>
                        <Button type="submit" bsStyle="primary"  className="btn">Sign-In</Button>
                    </form>
               
            </div>
           
      );
   }
}